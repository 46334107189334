.navbar {
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
  background-color: transparent !important;
  height: 8%;
  max-width: auto;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  animation: up 4s forwards;
}
.bannerTop {
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
  background-color: transparent !important;
  height: 8%;
  max-width: auto;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  animation: down 4s forwards;
}
.nav-menu {
  display: grid;
  background-color: transparent !important;
  grid-template-columns: repeat(4, auto);
  grid-gap: 2%;
  list-style: none;
  text-align: center;
  width: auto;
  justify-content: center;
  z-index: 998;
}
.nav-item {
  background-color: transparent !important;
  height: 25%;
  text-align: center;
}
.nav-links {
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-variant: small-caps;
}
.nav-links:hover {
  color: #46cc99;
  border-bottom: 4px solid #46cc99 !important;
  transition: all 0.2s ease-out !important;
}

@media (min-width: 0px) and (max-width: 376px) {
  .nav-menu {
    grid-gap: 0.5%;
    font-size: 0.9rem;
    padding-inline-start: 15px;
  }
  .nav-item {
    height: 0%;
  }
  .nav-links {
    padding: 0.5rem 0.5rem;
  }
}

@media (min-width: 377px) and (max-width: 639px) {
  .nav-menu {
    grid-gap: 0.5%;
    font-size: 1.0rem;
    padding-inline-start: 15px;
  }

  .nav-item {
    height: 0%;
  }
}

@media (min-width: 640px) and (max-width: 959px) {
  .nav-menu {
    grid-gap: 0.5%;
    padding-inline-start: 15px;
  }

  .nav-links {
    padding: 0.5rem 1rem;
    height: 50%;
  }
}

@media (min-width: 960px) and (max-width: 1099px) {
  .navbar {
    font-size: 0.9rem;
  }

}

@media (min-width: 1100px) and (max-width: 1399px) {
  .navbar {
    font-size: 1.0rem;
  }
}

@media (min-width: 1400px) and (max-width: 1919px) {
  .navbar {
    font-size: 1.2rem;
  }

  .nav-links {
    height: 50%;
  }

  .settingsSound {
    font-size: 1.0rem;
  }

  .settingsSound ion-icon {
    font-size: 1.1rem;
  }
}

@keyframes down {
  0% {
    height: 0%;
  }

  80% {
    height: 0%;
  }

  100% {
    height: 8%;
  }
}
@keyframes up {
  0% {
    height: 0%;
  }

  80% {
    height: 0%;
  }

  100% {
    height: 8%;
  }
}
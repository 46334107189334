/* 
  CTRL + K + 0 FOLD
  CTRL + K + J UNFOLD
  */
html {
  height: 100vh;
  background: radial-gradient(circle at 105%, #000000 -4%, #676768 12%, #7d7d7e 16%, #a0a0a1 25%, #ffffff 50%, #000000 110%);
  overflow-y: hidden;
  overflow-x: hidden;
  /* overflow: hidden;*/
  background-repeat: no-repeat;
  background-color: #000000;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  font-size: 100%;
}

b {
  color: #46cc99;
}

img[src=""] {
  display: none;
}
/* MOBILE */
@media (min-width: 0px) and (max-width: 639px) {
  html {
    height: 100vh;
    background: radial-gradient(circle at 120%, #000000 -4%, #676768 12%, #7d7d7e 16%, #a0a0a1 25%, #ffffff 50%, #000000 100%);
    scroll-behavior: smooth;
    overflow-y: scroll;
    /* overflw: hidden;*/
    background-repeat: no-repeat;
    background-color: #000000;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 100%;
  }
}

@media (min-width: 640px) and (max-width: 959px) {
  html {
    height: 100vh;
    background: radial-gradient(circle at 120%, #000000 -4%, #676768 12%, #7d7d7e 16%, #a0a0a1 25%, #ffffff 50%, #000000 100%);
    scroll-behavior: smooth;
    overflow-y: scroll;
    /* overflw: hidden;*/
    background-repeat: no-repeat;
    background-color: #000000;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 100%;
  }
}

/* ================================= OTHERS ==========================================*/
/* MOBILE */

.Loader {
  text-align: center;
  display: flex;
  background-color: #000000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.loaderText {
  background-color: transparent;
  color: #ffffff;
  position: absolute;
  font-size: 1.3rem;
  top: 54%;
}

.meLoader128 {
  background-color: transparent;
  position: absolute;
  height: auto;
  width: 11%;
  top: 22%;
}

.meLoader64 {
  background-color: transparent;
  position: absolute;
  height: auto;
  width: 11%;
  top: 22%;

  animation: fading 1s forwards;
  animation-delay: 2s;
}

.meLoader48 {
  background-color: transparent;
  position: absolute;
  height: auto;
  width: 11%;
  top: 22%;

  animation: fading 1s forwards;
  animation-delay: 1s;
}

.meLoader32 {
  background-color: transparent;
  position: absolute;
  height: auto;
  width: 11%;
  top: 22%;

  animation: fading 1s forwards;
}

@keyframes fading {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (min-width: 0px) and (max-width: 376px) {
  .loaderText {
    font-size: 0.9rem;
  }
  
  .meLoader128 {
    width: 45%;
    top: 20%;
  }

  .meLoader64 {
    width: 45%;
    top: 20%;
  }

  .meLoader48 {
    width: 45%;
    top: 20%;
  }

  .meLoader32 {
    width: 45%;
    top: 20%;
  }
}

@media (min-width: 377px) and (max-width: 639px) {
  .meLoader128 {
    width: 50%;
    top: 23%;
  }

  .meLoader64 {
    width: 50%;
    top: 23%;
  }

  .meLoader48 {
    width: 50%;
    top: 23%;
  }

  .meLoader32 {
    width: 50%;
    top: 23%;
  }

  .loaderText {
    background-color: transparent;
    color: #ffffff;
    position: absolute;
    font-size: 1.1rem;
    top: 54%;
  }

}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

/* ===================================== MAIN ======================================= */
/*MOBILE */
.myName {
  color: rgb(0, 0, 0);
  background-color: transparent;
  text-align: center;
  font-size: 3.5rem;
  font-weight: 550;
  margin-top: 35%;
  margin-left: 3%;
  letter-spacing: 0.5cm;
  font-variant: small-caps;
  font-family: 'Montserrat', sans-serif;
  user-select: none;
  animation: fadingText 4.5s forwards;
}

.subtitle {
  color: rgb(0, 0, 0);
  background-color: transparent;
  text-align: center;
  font-size: 3.0vh;
  font-weight: 100;
  margin-top: 25%;
  font-family: 'Montserrat', sans-serif;
  user-select: none;
  animation: fadingText 4.5s forwards;
}

.main {
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: 100vh;
  animation: bluring 1s forwards;
  overflow: hidden;
}

/*Larger Mobile*/
@media (min-width: 640px) and (max-width: 959px) {
  .myName {
    font-size: 4.5rem;
    font-weight: 550;
    margin-top: 4.0cm;
    letter-spacing: 0.5cm;
  }

  .subtitle {
    font-size: 1.9rem;
    font-weight: 100;
    margin-top: 3cm;
  }

}

/* Large MOBILE */
@media (min-width: 960px) and (max-width: 1099px) {
  .myName {
    font-size: 4.5rem;
    font-weight: 550;
    margin-top: 15%;
    letter-spacing: 0.5cm;
  }

  .subtitle {
    font-size: 2.2rem;
    font-weight: 100;
    margin-top: 10%;
  }

}

/* Medium */
@media (min-width: 1100px) and (max-width: 1399px) {
  .myName {
    font-size: 5.0rem;
    font-weight: 550;
    margin-top: 3.5cm;
    letter-spacing: 0.5cm;
  }

  .subtitle {
    font-size: 1.8rem;
    font-weight: 100;
    margin-top: 3.5cm;
  }
}

/* LAPTOP */
@media (min-width: 1400px) and (max-width: 1919px) {
  .myName {
    font-size: 7.0rem;
    font-weight: 550;
    margin-top: 4.5cm;
    letter-spacing: 0.5cm;
  }

  .subtitle {
    font-size: 2.2rem;
    font-weight: 100;
    margin-top: 3.5cm;
  }

}

/* MONITOR */
@media (min-width: 1920px) {
  .myName {
    font-size: 8.5rem;
    font-weight: 550;
    margin-top: 4.5cm;
  }

  .subtitle {
    font-size: 2.8rem;
    font-weight: 100;
    margin-top: 4.5cm;
  }
}

@keyframes bluring {
  0% {
    filter: blur(4px);
    background-color: black;
  }

  25% {
    filter: blur(0);
    background-color: #1A1A1A;
  }
}

/* =============================== ABOUT ME ====================================*/
/* MONITOR */
.aboutMeBackground {
  height: 100vh;
  width: 100%;
  background-color: #1A1A1A;
  animation: bluring 4s forwards;
}

.titlePage {
  position: absolute;
  top: 5.0rem;
  left: 6.9%;
  z-index: 1000;
  font-weight: 200;
  font-variant: small-caps;
  color: #ffffff;
  background-color: transparent;
  font-family: 'Montserrat', sans-serif;
  font-size: 3.8rem;
  user-select: none;
  letter-spacing: 0.2cm;
}

.subtitlePage {
  color: rgb(255, 255, 255);
  background-color: transparent;
  text-align: left;
  font-size: 2.5rem;
  font-weight: 550;
  letter-spacing: 0.3cm;
  font-variant: small-caps;
  font-family: 'Montserrat', sans-serif;
  user-select: none;
}

.personDiv {
  position: absolute;
  top: 25%;
  width: 40%;
  margin-left: 7%;
}

.engineerDiv {
  position: absolute;
  top: 25%;
  width: 40%;
  height: 68%;
  margin-left: 53%;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, #ffffff00 0%, #ffffff00 60%, #46cc99 60%, #46cc99 100%);
}

.textAboutMe {
  color: rgb(255, 255, 255);
  text-align: justify !important;
  font-size: 1.1rem;
  font-weight: 0;
  margin-top: 0.5cm;
  line-height: 2.5;
  font-family: 'Montserrat', sans-serif;
  user-select: none;
}

.textAboutMe a:-webkit-any-link {
  color: #46cc99;
  cursor: pointer;
  text-decoration: underline;
}

.textGrid {
  color: rgb(255, 255, 255);
  text-align: center !important;
  font-size: 1.0rem;
  font-weight: 0;
  margin-top: 0.5cm;
  line-height: 2;
  font-family: 'Montserrat', sans-serif;
  user-select: none;
}

.wrapper {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-auto-rows: minmax(80px, auto);

  color: rgb(255, 255, 255);
  font-family: 'Montserrat', sans-serif;
  user-select: none;
  text-align: center;
  justify-content: center;
  align-content: center;

}

.one {
  grid-column: 1;
  grid-row: 1;
}

.two {
  grid-column: 2;
  grid-row: 1;
}

.three {
  grid-column: 3;
  grid-row: 1;
}

.four {
  grid-column: 4;
  grid-row: 1;
}

.five {
  grid-column: 1;
  grid-row: 2;
}

.six {
  grid-column: 2;
  grid-row: 2;
}

.seven {
  grid-column: 3;
  grid-row: 2;
}

.eight {
  grid-column: 4;
  grid-row: 2;
}

@media (min-width: 0px) and (max-width: 639px) {
  .aboutMeBackground {
    position: absolute;
    height: 300vh;
    width: auto;
  }

  .titlePage {
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 4.5vh;
    width: 80%;
    text-align: center;
  }

  .subtitlePage {
    text-align: center;
    font-size: 3.0vh;
    width: 100%;
  }

  .personDiv {
    position: relative;
    float: left;
    top: 65%;
    height: auto;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10%;
  }

  .engineerDiv {
    top: 10%;
    height: auto;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .textAboutMe {
    font-size: 2vh;
  }
}

@media (min-width: 640px) and (max-width: 959px) {
  .aboutMeBackground {
    height: 300vh;
  }

  .titlePage {
    font-size: 2.1rem;
  }

  .subtitlePage {
    font-size: 1.4rem;
  }

  .textAboutMe {
    font-size: 0.9rem;
    line-height: 2;
  }

  .wrapper {
    grid-auto-rows: minmax(50px, auto);
  }
}

@media (min-width: 960px) and (max-width: 1099px) {
  .titlePage {
    font-size: 2.0rem;
  }

  .subtitlePage {
    font-size: 1.3rem;
  }

  .textAboutMe {
    font-size: 0.7rem;
  }

  .wrapper {
    grid-auto-rows: minmax(50px, auto);
  }
}

@media (min-width: 1100px) and (max-width: 1399px) {
  .titlePage {
    font-size: 2.4rem;
  }

  .subtitlePage {
    font-size: 1.7rem;
  }

  .textAboutMe {
    font-size: 0.8rem;
  }

  .wrapper {
    grid-auto-rows: minmax(50px, auto);
  }
}

@media (min-width: 1400px) and (max-width: 1919px) {

  .titlePage {
    font-size: 3.0rem;
  }

  .subtitlePage {
    font-size: 2.2rem;
  }

  .textAboutMe {
    font-size: 0.9rem;
  }

  .wrapper {
    grid-auto-rows: minmax(50px, auto);
    column-gap: 0cm;
  }
}

@keyframes bluringDarker {
  0% {
    filter: blur(4px);
    background-color: black;
  }

  40% {
    filter: blur(0);
    background-color: #1A1A1A;
  }
}

/*================================ PORTFOLIO =======================================*/

.portfolioSection {
  position: absolute;
  background-color: #1A1A1A;
  height: 100vh;
  width: 100%;
  z-index: -1001;
  animation: bluring 1s forwards;
}

.cardsContainer {
  position: absolute;
  top: 8%;
  width: 40%;
  height: 100%;
  z-index: 1100;

  display: grid;
  grid-template-columns: 50%;
  grid-template-rows: 25% 40% 30%;
  justify-content: space-evenly;
  background-color: #1A1A1A;

  animation: bluring 1s forwards;
}

.itemOne {
  grid-column: 1;
  grid-row: 1;
  height: 70%;
  margin-top: 5%;
  margin-left: -35%;
  font-size: 4.0rem;
}

.itemSecond {
  grid-column: 1;
  grid-row: 2;
  height: 80%;
  margin-left: -5%;
  font-size: 5.0rem;
}

.itemThird {
  grid-column: 1;
  grid-row: 3;
  height: 50%;
  margin-left: -35%;
  margin-top: -15%;
  font-size: 3.5rem;
}

.cardLinks {
  color: #f5000000;
  cursor: pointer;
  text-decoration: underline;
}

.cardTitle {
  left: 50%;
  top: 0%;
  height: 10%;
  z-index: 1001;
  /*animation: moveright 1s ease forwards 0.5s;*/
}

.cardTitleTextCenter {
  font-family: 'Poppins', sans-serif;
  -webkit-text-stroke: #000000 1px;
  color: #ffffff
}

.cardTitleText {
  font-family: 'Poppins', sans-serif;
  -webkit-text-stroke: #a1a1a1 1px;
  color: rgba(255, 255, 255, 0);
}

.cardInfo {
  position: absolute;
  left: 45%;
  top: 30%;
  width: 45%;
  z-index: 1001;
}

.cardInfoText {
  font-family: 'Montserrat', sans-serif;
  color: rgb(255, 255, 255);
  font-size: 2.5rem;
  line-height: 1.5;
}

.prevArea {
  position: absolute;
  top: 17%;
  left: 35%;
  z-index: 2000;
}

.nextArea {
  position: absolute;
  top: 75%;
  left: 35%;
  z-index: 2000;
}

.prevText,
.nextText {
  font-family: 'Montserrat', sans-serif;
  color: rgb(255, 255, 255);
  font-size: 4.0rem;
}

.prevText:hover,
.nextText:hover {
  color: #46cc99;
}

.wrapperPortfolio {
  position: absolute;
  top: 70%;
  left: 53%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-auto-rows: minmax(80px, auto);
  column-gap: 80px;

  color: rgb(255, 255, 255);
  font-family: 'Montserrat', sans-serif;
  user-select: none;
  text-align: center;
  justify-content: center;
  align-content: center;

}

.wrapperPortfolio>.one>img,
.wrapperPortfolio>.two>img,
.wrapperPortfolio>.three>img,
.wrapperPortfolio>.four>img {
  height: 70px;
  width: 70px;
}

.indexBox {
  position: absolute;
  top: 44%;
  left: 3%;
  width: 15%;
  z-index: 10000;
}

.indexNumber {
  font-family: 'Montserrat', sans-serif;
  color: rgb(255, 255, 255);
  font-size: 3.5rem;
}
@media (min-width: 0px) and (max-width: 376px){
  .portfolioSection {
    position: absolute;
    background-color: #1A1A1A;
    height: 100vh;
    width: 100%;
    z-index: -1001;
    animation: bluring 1s forwards;
  }
  
  .cardsContainer {
    position: absolute;
    top: -15%;
    margin-left: 10%;
    width: 40%;
    height: 100%;
    z-index: 1100;
  
    display: grid;
    grid-template-columns: 50%;
    grid-template-rows: 25% 40% 30%;
    justify-content: space-evenly;
    background-color: #1A1A1A;
  
    animation: bluring 1s forwards;
  }
  
  .itemOne {
    grid-column: 1;
    grid-row: 1;
    height: 70%;
    margin-top: 5%;
    margin-left: -35%;
    font-size: 2.0rem;
    visibility: hidden;
  }
  
  .itemSecond {
    grid-column: 1;
    grid-row: 2;
    top: 0%;
    margin-left: 15%;
    font-size: 2.2rem;
  }
  
  .itemThird {
    grid-column: 1;
    grid-row: 3;
    height: 50%;
    margin-left: -35%;
    margin-top: -15%;
    font-size: 2.5rem;
    visibility: hidden;
  }
  
  .cardLinks {
    color: #f5000000;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .cardTitle {
    z-index: 1001;
    /*animation: moveright 1s ease forwards 0.5s;*/
  }
  
  .cardTitleTextCenter {
    font-family: 'Poppins', sans-serif;
    -webkit-text-stroke: #000000 1px;
    color: #ffffff
  }
  
  .cardTitleText {
    font-family: 'Poppins', sans-serif;
    -webkit-text-stroke: #a1a1a1 1px;
    color: rgba(255, 255, 255, 0);
  }
  
  .cardInfo {
    position: absolute;
    left: 15%;
    top: 25%;
    width: 70%;
    z-index: 2001;
    text-align: justify;
  }
  
  .cardInfoText {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 1.3rem;
    line-height: 1.5;
  }
  
  .prevArea {
    position: absolute;
    top: 80%;
    left: 15%;
    z-index: 2000;
    transform: rotate(270deg);
  }
  
  .nextArea {
    position: absolute;
    top: 80%;
    left: 75%;
    z-index: 2000;
    transform: rotate(270deg);
  }
  
  .prevText,
  .nextText {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 3.0rem;
  }
  
  .prevText:hover,
  .nextText:hover {
    color: #46cc99;
  }
  
  .wrapperPortfolio {
    position: absolute;
    top: 60%;
    left: 20%;
    display: grid;
    z-index: 2000;
    grid-template-columns: 25% 25% 25% 25%;
    grid-auto-rows: minmax(80px, auto);
    column-gap: 20px;
  
    color: rgb(255, 255, 255);
    font-family: 'Montserrat', sans-serif;
    user-select: none;
    text-align: center;
    justify-content: center;
    align-content: center;
  
  }
  
  .wrapperPortfolio>.one,
  .wrapperPortfolio>.two,
  .wrapperPortfolio>.three,
  .wrapperPortfolio>.four {
    font-size: 40px;
  }
  
  .indexBox {
    position: absolute;
    top: 80%;
    left: 45%;
    width: 15%;
    z-index: 10000;
  }
  
  .indexNumber {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 2.5rem;
  }
}
@media (min-width: 377px) and (max-width: 639px) {
  .portfolioSection {
    position: absolute;
    background-color: #1A1A1A;
    height: 100vh;
    width: 100%;
    z-index: -1001;
    animation: bluring 1s forwards;
  }
  
  .cardsContainer {
    position: absolute;
    top: -15%;
    margin-left: 10%;
    width: 40%;
    height: 100%;
    z-index: 1100;
  
    display: grid;
    grid-template-columns: 50%;
    grid-template-rows: 25% 40% 30%;
    justify-content: space-evenly;
    background-color: #1A1A1A;
  
    animation: bluring 1s forwards;
  }
  
  .itemOne {
    grid-column: 1;
    grid-row: 1;
    visibility: hidden;
  }
  
  .itemSecond {
    grid-column: 1;
    grid-row: 2;
    height: 80%;
    top: 0%;
    margin-left: 15%;
    font-size: 2.5rem;
  }
  
  .itemThird {
    grid-column: 1;
    grid-row: 3;
    visibility: hidden;
  }
  
  .cardLinks {
    color: #f5000000;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .cardTitle {
    z-index: 1001;
    /*animation: moveright 1s ease forwards 0.5s;*/
  }
  
  .cardTitleTextCenter {
    font-family: 'Poppins', sans-serif;
    -webkit-text-stroke: #000000 1px;
    color: #ffffff
  }
  
  .cardTitleText {
    font-family: 'Poppins', sans-serif;
    -webkit-text-stroke: #a1a1a1 1px;
    color: rgba(255, 255, 255, 0);
  }
  
  .cardInfo {
    position: absolute;
    left: 15%;
    top: 25%;
    width: 70%;
    z-index: 2001;
    text-align: justify;
  }
  
  .cardInfoText {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 1.3rem;
    line-height: 1.5;
  }
  
  .prevArea {
    position: absolute;
    top: 80%;
    left: 10%;
    z-index: 2000;
    transform: rotate(270deg);
  }
  
  .nextArea {
    position: absolute;
    top: 80%;
    left: 75%;
    z-index: 2000;
    transform: rotate(270deg);
  }
  
  .prevText,
  .nextText {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 3.0rem;
  }
  
  .prevText:hover,
  .nextText:hover {
    color: #46cc99;
  }
  
  .wrapperPortfolio {
    position: absolute;
    top: 60%;
    left: 20%;
    display: grid;
    z-index: 2000;
    grid-template-columns: 25% 25% 25% 25%;
    grid-auto-rows: minmax(80px, auto);
    column-gap: 20px;
  
    color: rgb(255, 255, 255);
    font-family: 'Montserrat', sans-serif;
    user-select: none;
    text-align: center;
    justify-content: center;
    align-content: center;
  
  }
  
  .wrapperPortfolio>.one,
  .wrapperPortfolio>.two,
  .wrapperPortfolio>.three,
  .wrapperPortfolio>.four {
    font-size: 50px;
  }
  
  .indexBox {
    position: absolute;
    top: 80%;
    left: 43%;
    width: 15%;
    z-index: 10000;
  }
  
  .indexNumber {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 2.5rem;
  }
}

@media (min-width: 640px) and (max-width: 959px) {
  .portfolioSection {
    position: absolute;
    background-color: #1A1A1A;
    height: 100vh;
    width: 100%;
    z-index: -1001;
    animation: bluring 1s forwards;
  }
  
  .cardsContainer {
    position: absolute;
    top: -15%;
    left: 8%;
    width: 40%;
    height: 100%;
    z-index: 1100;
  
    display: grid;
    grid-template-columns: 50%;
    grid-template-rows: 25% 40% 30%;
    justify-content: space-evenly;
    background-color: #1A1A1A;
  
    animation: bluring 1s forwards;
  }
  
  .itemOne {
    grid-column: 1;
    grid-row: 1;
    height: 70%;
    margin-top: 5%;
    margin-left: -35%;
    font-size: 2.0rem;
    visibility: hidden;
  }
  
  .itemSecond {
    grid-column: 1;
    grid-row: 2;
    height: 80%;
    margin-left: -5%;
    font-size: 3.0rem;
  }
  
  .itemThird {
    grid-column: 1;
    grid-row: 3;
    height: 50%;
    margin-left: -35%;
    margin-top: -15%;
    font-size: 2.5rem;
    visibility: hidden;
  }
  
  .cardLinks {
    color: #f5000000;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .cardTitle {
    z-index: 1001;
    /*animation: moveright 1s ease forwards 0.5s;*/
  }
  
  .cardTitleTextCenter {
    font-family: 'Poppins', sans-serif;
    -webkit-text-stroke: #000000 1px;
    color: #ffffff
  }
  
  .cardTitleText {
    font-family: 'Poppins', sans-serif;
    -webkit-text-stroke: #a1a1a1 1px;
    color: rgba(255, 255, 255, 0);
  }
  
  .cardInfo {
    position: absolute;
    left: 15%;
    top: 25%;
    width: 100%;
    z-index: 2001;
  }
  
  .cardInfoText {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    line-height: 1.5;
  }
  
  .prevArea {
    position: absolute;
    top: 80%;
    left: 70%;
    z-index: 2000;
  }
  
  .nextArea {
    position: absolute;
    top: 80%;
    left: 15%;
    z-index: 2000;
  }
  
  .prevText,
  .nextText {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 3.0rem;
  }
  
  .prevText:hover,
  .nextText:hover {
    color: #46cc99;
  }
  
  .wrapperPortfolio {
    position: absolute;
    top: 70%;
    left: 53%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-auto-rows: minmax(80px, auto);
    column-gap: 20px;
  
    color: rgb(255, 255, 255);
    font-family: 'Montserrat', sans-serif;
    user-select: none;
    text-align: center;
    justify-content: center;
    align-content: center;
  
  }
  
  .wrapperPortfolio>.one,
  .wrapperPortfolio>.two,
  .wrapperPortfolio>.three,
  .wrapperPortfolio>.four {
    font-size: 40px;
  }
  
  .indexBox {
    position: absolute;
    top: 80%;
    left: 42%;
    width: 15%;
    z-index: 10000;
  }
  
  .indexNumber {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 2.5rem;
  }
}
@media (min-width: 960px) and (max-width: 1099px) {}

@media (min-width: 1100px) and (max-width: 1399px) {}

@media (min-width: 1400px) and (max-width: 1919px) {
  .portfolioSection {
    position: absolute;
    background-color: #1A1A1A;
    height: 100vh;
    width: 100%;
    z-index: -1001;
    animation: bluring 1s forwards;
  }

  .cardsContainer {
    position: absolute;
    top: 8%;
    width: 40%;
    height: 100%;
    z-index: 1100;

    display: grid;
    grid-template-columns: 50%;
    grid-template-rows: 25% 40% 30%;
    justify-content: space-evenly;
    background-color: #1A1A1A;

    animation: bluring 1s forwards;
  }

  .itemOne {
    grid-column: 1;
    grid-row: 1;
    height: 70%;
    margin-top: 5%;
    margin-left: -35%;
    font-size: 4.0rem;
  }

  .itemSecond {
    grid-column: 1;
    grid-row: 2;
    height: 80%;
    margin-left: -5%;
    font-size: 4.0rem;
  }

  .itemThird {
    grid-column: 1;
    grid-row: 3;
    height: 50%;
    margin-left: -35%;
    margin-top: -15%;
    font-size: 3.0rem;
  }

  .cardsItemImg {
    height: 100%;
  }

  .cardLinks {
    color: #f5000000;
    cursor: pointer;
    text-decoration: underline;
  }

  .cardsItemText {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    z-index: 101;
  }

  .cardTitle {
    left: 50%;
    top: 0%;
    height: 10%;
    z-index: 1001;
    /*animation: moveright 1s ease forwards 0.5s;*/
  }

  .cardTitleTextCenter {
    font-family: 'Poppins', sans-serif;
    -webkit-text-stroke: #000000 1px;
    color: #ffffff
  }

  .cardTitleText {
    font-family: 'Poppins', sans-serif;
    -webkit-text-stroke: #a1a1a1 1px;
    color: rgba(255, 255, 255, 0);
  }

  .cardInfo {
    position: absolute;
    left: 45%;
    top: 25%;
    width: 45%;
    z-index: 1001;
  }

  .cardInfoText {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 2.5rem;
    line-height: 1.2;
  }

  .prevArea {
    position: absolute;
    top: 17%;
    left: 35%;
    z-index: 2000;
  }

  .nextArea {
    position: absolute;
    top: 75%;
    left: 35%;
    z-index: 2000;
  }

  .prevText,
  .nextText {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 4.0rem;
  }

  .prevText:hover,
  .nextText:hover {
    color: #46cc99;
  }

  .wrapperPortfolio {
    position: absolute;
    top: 70%;
    left: 53%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-auto-rows: minmax(80px, auto);
    column-gap: 80px;

    color: rgb(255, 255, 255);
    font-family: 'Montserrat', sans-serif;
    user-select: none;
    text-align: center;
    justify-content: center;
    align-content: center;

  }

  .wrapperPortfolio>.one,
  .wrapperPortfolio>.two,
  .wrapperPortfolio>.three,
  .wrapperPortfolio>.four {
    font-size: 65px;
  }

  .indexBox {
    position: absolute;
    top: 44%;
    left: 3%;
    width: 15%;
    z-index: 10000;
  }

  .indexNumber {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 3.5rem;
  }
}
@keyframes visibility {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* ====================================== CONTACT ME ====================================== */
.europe {
  background-color: transparent;
  position: relative;
  width: 100%;
  height: fit-content;
  right: -20%;
  top: 7%;
  user-select: none;
  animation: bluring 1s forwards;
}

.backgroundContactMe {
  background-color: #1A1A1A;
  height: 100vh;
  z-index: -999;
}

.contactMeText {
  position: absolute;
  top: 20%;
  left: 7%;
  z-index: 1000;

  color: #ffffff;
  background-color: transparent;

  font-family: 'Montserrat', sans-serif;
  user-select: none;
  font-size: 1.6rem;
  font-weight: 100;
  line-height: 3;
}

.wrapSocial {
  position: absolute;
  top: 45%;
  left: 5%;
  display: grid;
  grid-template-columns: 10% 60%;
  grid-auto-rows: minmax(80px, auto);

  color: rgb(255, 255, 255);
  user-select: none;
  text-align: center;
  justify-content: center;
  align-items: center;
}

ion-icon {
  display: inline;
  color: rgb(255, 255, 255);
}

.wrapLog:hover ion-icon {
  color: #46cc99;
}

.wrapSocial>.one {
  grid-column: 1;
  grid-row: 1;
}

.wrapSocial>.two {
  grid-column: 1;
  grid-row: 2;
}

.wrapSocial>.three {
  grid-column: 1;
  grid-row: 3;
}

.wrapSocial>.four {
  grid-column: 1;
  grid-row: 4;
}

.wrapSocial>.five {
  grid-column: 2;
  grid-row: 1;
  overflow: hidden;
  animation: slidingText 1s ease forwards 0.3s;
  color: #ffffff00;
}

.wrapSocial>.six {
  grid-column: 2;
  grid-row: 2;
  overflow: hidden;
  animation: slidingText 1s ease forwards 0.6s;
  color: #ffffff00;
}

.wrapSocial>.seven {
  grid-column: 2;
  grid-row: 3;
  overflow: hidden;
  animation: slidingText 1s ease forwards 0.9s;
  color: #ffffff00;
}

.wrapSocial>.eight {
  grid-column: 2;
  grid-row: 4;
  overflow: hidden;
  animation: slidingText 1s ease forwards 1.2s;
  color: #ffffff00;
}

.contactInfo {
  background-color: transparent;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  user-select: none;
  font-size: 1.0rem;
  font-weight: 100;
}

@keyframes slidingText {
  0% {
    color: rgba(255, 255, 255, 0);
    margin-left: -75%;
  }

  50% {
    color: rgba(255, 255, 255, 0);
  }

  100% {
    color: white;
    margin-left: 15%;
  }
}

@media (min-width: 0px) and (max-width: 376px) {
  .europe {
    visibility: hidden;
  }

  .contactMeText {
    position: absolute;
    top: 10%;
    margin-left: 5%;
    margin-right: 10%;
    font-size: 0.9rem;
    text-align: justify;
    text-justify: inter-word;
  }

  .wrapSocial {
    top: 40%;
    left: 0%;
    column-gap: -8%;
  }

  .contactInfo {
    font-size: 0.75rem;
  }

  .wrapSocial>.five {
    animation: slidingText_app 1s ease forwards 0.3s;
  }

  .wrapSocial>.six {
    animation: slidingText_app 1s ease forwards 0.6s;
  }

  .wrapSocial>.seven {
    animation: slidingText_app 1s ease forwards 0.9s;
  }

  .wrapSocial>.eight {
    animation: slidingText_app 1s ease forwards 1.2s;
  }

  @keyframes slidingText_app {
    0% {
      color: rgba(255, 255, 255, 0);
      margin-left: -75%;
    }

    50% {
      color: rgba(255, 255, 255, 0);
    }

    100% {
      color: white;
      margin-left: 9%;
    }
  }
}

@media (min-width: 377px) and (max-width: 639px) {


  .europe {
    visibility: hidden;
  }

  .contactMeText {
    position: absolute;
    top: 10%;
    margin-left: 5%;
    margin-right: 10%;
    font-size: 1.1rem;
  }

  .wrapSocial {
    top: 47%;
    left: 0%;
    column-gap: -7%;
  }

  .contactInfo {
    font-size: 0.8rem;
  }

  .wrapSocial>.five {
    animation: slidingText_app 1s ease forwards 0.3s;
  }

  .wrapSocial>.six {
    animation: slidingText_app 1s ease forwards 0.6s;
  }

  .wrapSocial>.seven {
    animation: slidingText_app 1s ease forwards 0.9s;
  }

  .wrapSocial>.eight {
    animation: slidingText_app 1s ease forwards 1.2s;
  }

  @keyframes slidingText_app {
    0% {
      color: rgba(255, 255, 255, 0);
      margin-left: -75%;
    }

    50% {
      color: rgba(255, 255, 255, 0);
    }

    100% {
      color: white;
      margin-left: 9%;
    }
  }
}

@media (min-width: 640px) and (max-width: 959px) {

  .contactMeText {
    top: 260px;
    left: 50px;
    font-size: 30px;
    margin-top: 2cm;
  }

  .contactMeEmail {
    bottom: 190px;
    left: 50px;
    margin: 0;
    font-size: 23px;
    display: inline-block;
  }

}

@media (min-width: 960px) and (max-width: 1099px) {
  .contactMeText {
    top: 27%;
  }

  .contactMeEmail {
    font-size: 1.0rem;
  }
}

@media (min-width: 1100px) and (max-width: 1399px) {}

@media (min-width: 1400px) and (max-width: 1919px) {
  .contactMeText {
    top: 15%;
  }

  .wrapSocial {
    top: 42%;
  }
}